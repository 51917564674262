import packageInfo from '../../../package.json';
import {useLocation, useMatch, useNavigate, useParams} from "react-router-dom"
import {useEffect, useState} from "react";
import {BaseRecord} from "../../models/BaseRecord";
import {getArchiveRootFolder, getPath, getRecord} from "../../helpers/GoogleStorageApiHelper";
import {Loader} from "../Loader/Loader";
import {Error} from "../Error/Error";
import * as DeviceType from "../../models/DeviceType";
import {DPM, LEEB, MF1M, TP1M, TUD2, TUD3, UCI, UCI_PRO, UT1M, UT1M_CT, UT1M_IP} from "../../models/DeviceType";
import {TudViewer} from "./TudViewer";
import {TudRecord} from "../../models/TudRecord";
import {TpViewer} from "./TpViewer";
import {
    COATING_THICKNESS_MEASUREMENT_TYPE,
    DEW_POINT_MEASUREMENT_TYPE,
    SURFACE_TEMPERATURE_MEASUREMENT_TYPE,
    TpRecord
} from "../../models/TpRecord";
import {UtViewer} from "./UtViewer";
import {UtRecord} from "../../models/UtRecord";
import {Alert, Button, ButtonGroup, Modal} from "react-bootstrap";
import IconCsvSvg from "../Icons/IconCsvSvg";
import IconPdfSvg from "../Icons/IconPdfSvg";
import IconShareSvg from "../Icons/IconShareSvg";
import './Viewer.css';
import IconCloseSvg from "../Icons/IconCloseSvg";
import {
    buildListPath,
    buildSharedRecordPath,
    HOME_ROUTE,
    RECORD_PAGE_NAME,
    SHARED_RECORD_PAGE_NAME,
    SHARED_RECORD_ROUTE,
    unescapePath
} from "../../routes";
import {useAbortStatus} from "../../hooks/AbortHook";
import {HorizontalDivider} from "../Divider/HorizontalDivider";
import {ResourceNotFoundError} from "../../errors/GoogleDriveErrors";
import {NotFound} from "../NotFound/NotFound";
import {
    createDpmCsv,
    createLeebCsv,
    createMfCsv,
    createTpCsv,
    createTpDewPointCsv,
    createTpSurfaceTemperatureCsv,
    createTudCsv,
    createUciCsv,
    createUciProCsv,
    createUtCsv
} from "../../csv/CsvHelper";
import {SharedRecordLinkDialog} from "../SharedRecordLinkDialog/SharedRecordLinkDialog";
import {useTranslation} from "react-i18next";
import {getAndroidDynamicLink, getDomainName, getShortLink} from "../../helpers/FirebaseDynamicLinksHelper";
import {isAndroid} from 'react-device-detect';
import {useLocale} from "../../hooks/LocaleHook";
import logoPng from "../AppBar/logo_main.png";
import {createPdf} from "../../pdf/PdfHelper";
import {PdfCreationError} from "../../errors/PdfErrors";
import {IS_DEMO_APP} from "../../AppSettings";
import {useAnalytics} from "../../hooks/AnalyticsHook";
import {UciRecord} from "../../models/UciRecord";
import {UciViewer} from "./UciViewer";
import {MfRecord} from "../../models/MfRecord";
import {MfViewer} from "./MfViewer";
import {Breadcrumbs} from "../ListExplorer/Breadcrumbs";
import {ArchiveItem} from "../../models/ArchiveItem";
import {useGoogleApi} from "../../google_api/GoogleApiProvider";
import {LeebRecord} from '../../models/LeebRecord';
import {LeebViewer} from './LeebViewer';
import {DpmRecord} from '../../models/DpmRecord';
import {DpmViewer} from './DpmViewer';
import {UciProRecord} from "../../models/UciProRecord";
import {UciProViewer} from "./UciProViewer";


export function Viewer() {
    const {t} = useTranslation();
    const locale = useLocale();
    const isAborted = useAbortStatus();
    const history = useNavigate();
    const location = useLocation();
    const sharedRecordRouteMath = useMatch(SHARED_RECORD_ROUTE);
    useAnalytics(sharedRecordRouteMath !== null ? SHARED_RECORD_PAGE_NAME : RECORD_PAGE_NAME);
    const googleDrive = useGoogleApi().drive;
    const [path, setPath] = useState(new Array<ArchiveItem>());
    const {id} = useParams();
    const recordId = id ? unescapePath(id) : id;
    const [record, setRecord] = useState(null as BaseRecord | null);
    const [error, setError] = useState(null as Error | null);
    const [shareRecordId, setShareRecordId] = useState(null as string | null);
    const [showMobilePrompt, setShowMobilePrompt] = useState(false);
    const [isMobilePromptDisplayed, setIsMobilePromptIsDisplayed] = useState(false);
    const [showLinkToast, setShowLinkToast] = useState(false);
    const [linkToastTimerId, setLinkToastTimerId] = useState(null as NodeJS.Timeout | null);
    const hideLinkToast = () => {
        if (linkToastTimerId) {
            clearTimeout(linkToastTimerId);
            setLinkToastTimerId(null);
        }
        setShowLinkToast(false);
    }
    const openLinkToast = () => {
        if (linkToastTimerId) {
            clearTimeout(linkToastTimerId);
            setLinkToastTimerId(null);
        }
        setShowLinkToast(true);
        const id = setTimeout(hideLinkToast, 2000);
        setLinkToastTimerId(id);
    };
    const [showPdfToast, setShowPdfToast] = useState(false);
    const [pdfToastTimerId, setPdfToastTimerId] = useState(null as NodeJS.Timeout | null);
    const hidePdfToast = () => {
        if (pdfToastTimerId) {
            clearTimeout(pdfToastTimerId);
            setPdfToastTimerId(null);
        }
        setShowPdfToast(false);
    }
    const openPdfToast = () => {
        if (pdfToastTimerId) {
            clearTimeout(pdfToastTimerId);
            setPdfToastTimerId(null);
        }
        setShowPdfToast(true);
        const id = setTimeout(hidePdfToast, 2000);
        setPdfToastTimerId(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (googleDrive && recordId) {
            getRecord(googleDrive, recordId, !(IS_DEMO_APP || sharedRecordRouteMath !== null)).then(record => {
                if (!isAborted) {
                    setRecord(record);
                    setError(null);
                }
            }).catch((e) => {
                if (!isAborted) {
                    setError(e);
                }
            });
        }
    }, [googleDrive, recordId]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setPath([]);
        if (googleDrive && sharedRecordRouteMath === null) {
            (recordId ? getPath(googleDrive, recordId) : getArchiveRootFolder(googleDrive).then(root => [root])).then(path => {
                if (!isAborted) {
                    if (path.length > 0) {
                        if (record){
                            path[path.length - 1].name = record.name;
                        } else {
                            path[path.length - 1].name = path[path.length - 1].name.replace(".record", "");
                        }
                        setPath(path);
                    } else {
                        history(buildListPath());
                    }
                }
            }).catch((e) => {
                if (!isAborted) {
                    setError(e);
                }
            });
        }
    }, [googleDrive, recordId]);
    useEffect(() => {
        if (path && record){
            if (path[path.length - 1]) {
                path[path.length - 1].name = record.name;
                setPath(path);
            }
        }
    }, [record]);
    useEffect(() => {
        const description = document.querySelector("meta[name='description']");
        if (record) {
            document.title = `${packageInfo.title} - ${record.name}`;
            if (description) {
                description.setAttribute('content', `${packageInfo.description} - ${record.name}`);
            }
        }
        return () => {
            document.title = packageInfo.title;
            if (description) {
                description.setAttribute('content', packageInfo.description);
            }
        }
    })
    const deviceType = record?.deviceType;
    const makeCsv = () => {
        if (record) {
            switch (record.deviceType) {
                case TP1M:
                    const tpRecord = record as TpRecord;
                    const measurementType = tpRecord.measurementType ?? COATING_THICKNESS_MEASUREMENT_TYPE;
                    if (measurementType === COATING_THICKNESS_MEASUREMENT_TYPE) {
                        const measurements = tpRecord.measurements;
                        if (measurements) {
                            createTpCsv(record.name, measurements);
                        }
                    }
                    if (measurementType === SURFACE_TEMPERATURE_MEASUREMENT_TYPE) {
                        const measurements = tpRecord.surfaceTemperatureMeasurements;
                        if (measurements) {
                            createTpSurfaceTemperatureCsv(record.name, measurements);
                        }
                    }
                    if (measurementType === DEW_POINT_MEASUREMENT_TYPE) {
                        const measurements = tpRecord.dewPointMeasurements;
                        if (measurements) {
                            createTpDewPointCsv(record.name, measurements);
                        }
                    }
                    break;
                case TUD2:
                case TUD3:
                    const tudMeasurements = (record as TudRecord).measurements;
                    if (tudMeasurements) {
                        createTudCsv(record.name, tudMeasurements);
                    }
                    break;
                case LEEB:
                    const leebMeasurements = (record as LeebRecord).measurements;
                    if (leebMeasurements) {
                        createLeebCsv(record.name, leebMeasurements);
                    }
                    break;
                case UCI:
                    const uciMeasurements = (record as UciRecord).measurements;
                    if (uciMeasurements) {
                        createUciCsv(record.name, uciMeasurements);
                    }
                    break;
                case UCI_PRO:
                    const uciProMeasurements = (record as UciProRecord).measurements;
                    if (uciProMeasurements) {
                        createUciProCsv(record.name, uciProMeasurements);
                    }
                    break;
                case UT1M:
                case UT1M_IP:
                case UT1M_CT:
                    const utMeasurements = (record as UtRecord).measurements;
                    if (utMeasurements) {
                        createUtCsv(record.name, utMeasurements);
                    }
                    break;
                case MF1M:
                    const mfMeasurements = (record as MfRecord).measurements;
                    if (mfMeasurements) {
                        createMfCsv(record.name, mfMeasurements);
                    }
                    break;
                case DPM:
                    const dpmMeasurements = (record as DpmRecord).measurements;
                    if (dpmMeasurements) {
                        createDpmCsv(record.name, dpmMeasurements);
                    }
                    break;
            }
        }
    };
    const makePdf = () => {
        if (record && recordId) {
            const domainName = getDomainName();
            const rawLink = domainName + buildSharedRecordPath(recordId);
            const pdfPromise = getShortLink(rawLink).then(link => {
                if (link) {
                    return createPdf(googleDrive, t, locale, logoPng, recordId, record, link);
                }
                throw new PdfCreationError();
            });
            pdfPromise.then(jsPDF => jsPDF.save(`${record.name}.pdf`))
                .catch(() => openPdfToast());
        }
    };
    const share = () => {
        if (record && recordId) {
            setShareRecordId(recordId);
        }
    };
    const close = () => {
        if (sharedRecordRouteMath === null && path.length > 1) {
            history(buildListPath(path[path.length - 2].id));
        } else {
            history(HOME_ROUTE);
        }
    };
    const keyDownListener = (ev: KeyboardEvent) => {
        if (ev.key === 'Escape' || ev.key === 'Esc') {
            close();
            return false;
        }
        return true;
    }
    useEffect(() => {
        document.addEventListener("keydown", keyDownListener);
        return () => {
            document.removeEventListener("keydown", keyDownListener);
        }
    });
    const openInApp = () => {
        setShowMobilePrompt(false);
        if (recordId) {
            const domainName = getDomainName();
            const rawLink = domainName + buildSharedRecordPath(recordId);
            window.location.assign(getAndroidDynamicLink(rawLink));
        }
    }
    if (!IS_DEMO_APP && !isMobilePromptDisplayed && sharedRecordRouteMath !== null && isAndroid) {
        setIsMobilePromptIsDisplayed(true);
        setShowMobilePrompt(true);
    }
    return (
        <div className="container-grow">
            <div id="pdf-canvas-container" style={{position: "absolute", top: "-10000px", left: "-10000px"}}>
                <canvas id="pdf-canvas"/>
            </div>
            <div className="d-flex flew-row justify-content-between align-items-center">
                <ButtonGroup className="my-4">
                    <Button variant="outline-primary"
                            onClick={makeCsv}><IconCsvSvg/></Button>
                    <Button variant="outline-primary"
                            onClick={makePdf}><IconPdfSvg/></Button>
                    <Button variant="outline-primary"
                            onClick={share}><IconShareSvg/></Button>
                </ButtonGroup>
                <div className="d-flex flex-grow-1 mx-4">
                    <Breadcrumbs path={path}
                                 locationChangeListener={locationId => history(buildListPath(locationId))}/>
                </div>
                <div className="button-close" onClick={close}><IconCloseSvg/></div>
            </div>
            <HorizontalDivider/>
            {error && ((error instanceof ResourceNotFoundError) ? <NotFound/> :
                <Error error={error} retryClickHandler={() => history(location.pathname, {replace: true})}/>)}
            {(!error && !record) && <Loader/>}
            {(!error && record && recordId) &&
                <div className="container-grow">
                    <h3 className="mt-4">{record.name}</h3>
                    {(deviceType === DeviceType.TUD2 || deviceType === DeviceType.TUD3) &&
                        <TudViewer drive={googleDrive} recordId={recordId} record={record as TudRecord}/>}
                    {(deviceType === DeviceType.LEEB) &&
                        <LeebViewer drive={googleDrive} recordId={recordId} record={record as LeebRecord}/>}
                    {(deviceType === DeviceType.UCI) &&
                        <UciViewer drive={googleDrive} recordId={recordId} record={record as UciRecord}/>}
                    {(deviceType === DeviceType.UCI_PRO) &&
                        <UciProViewer drive={googleDrive} recordId={recordId} record={record as UciProRecord}/>}
                    {deviceType === DeviceType.TP1M &&
                        <TpViewer drive={googleDrive} recordId={recordId} record={record as TpRecord}/>}
                    {(deviceType === DeviceType.UT1M || deviceType === DeviceType.UT1M_IP || deviceType === DeviceType.UT1M_CT) &&
                        <UtViewer drive={googleDrive} recordId={recordId} record={record as UtRecord}/>}
                    {(deviceType === DeviceType.MF1M) &&
                        <MfViewer drive={googleDrive} recordId={recordId} record={record as MfRecord}/>}
                    {(deviceType === DeviceType.DPM) &&
                        <DpmViewer drive={googleDrive} recordId={recordId} record={record as DpmRecord}/>}
                </div>
            }
            {(!error && record) &&
                <SharedRecordLinkDialog recordId={shareRecordId} recordName={record.name}
                                        closeHandler={() => setShareRecordId(null)}
                                        linkCopyHandler={() => openLinkToast()}/>}
            <Alert show={showLinkToast} variant={"success"} className="toast-container-viewer"
                   onClose={() => hideLinkToast()}
                   dismissible>
                <span
                    className="d-flex justify-content-center align-items-center toast-text-viewer">{t("link_copied")}</span>
            </Alert>
            <Alert show={showPdfToast} variant={"danger"} className="toast-container-viewer"
                   onClose={() => hidePdfToast()}
                   dismissible>
                <span
                    className="d-flex justify-content-center align-items-center toast-text-viewer">{t("pdf_save_failed")}</span>
            </Alert>
            <Modal show={showMobilePrompt}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("record_view")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t("record_view_instruction")} <strong>Novotest Bluetooth</strong></p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => openInApp()}>{t("open_in_app")}</Button>
                    <Button variant="secondary" onClick={() => setShowMobilePrompt(false)}>{t("cancel")}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
