export const TUD2 = "TUD2";
export const TUD3 = "TUD3";
export const TP1M = "TP1M";
export const UT1M = "UT1M";
export const UT1M_IP = "UT1M_IP";
export const UT1M_CT = "UT1M_CT";
export const LEEB = "LEEB";
export const UCI = "UCI";
export const UCI_PRO = "UCI_PRO";
export const MF1M = "MF1M";
export const DPM = "DPM";
