import {MediaRecord} from "../../models/MediaRecord";
import {useEffect, useState} from "react";
import {getMediaFile} from "../../helpers/GoogleStorageApiHelper";
import {Error} from "../Error/Error";
import './PhotoViewer.css';
import {MeasurementMarker} from "./MeasurementsWithPhoto";
import {formatUciProScaleName, formatUciProScaleValue} from "../../helpers/FormatHelper";
import {LoaderSmall} from "../Loader/LoaderSmall";
import {ResourceNotFoundError} from "../../errors/GoogleDriveErrors";
import {NotFoundSmall} from "../NotFound/NotFoundSmall";
import {ErrorSmall} from "../Error/ErrorSmall";
import {useTranslation} from "react-i18next";
import {MeasurementsWithPhotoPreview} from "./MeasurementsWithPhotoPreview";
import {UciProMeasurement} from "../../models/UciProMeasurement";

interface Props {
    drive: any;
    recordId: string;
    mediaRecord: MediaRecord;
    measurement: UciProMeasurement[];
    imageLoadListener?: () => void;
}

export function UciProPhotoPreview(props: Props) {
    const {t} = useTranslation();
    const [mediaUrl, setMediaUrl] = useState(null as string | null);
    const [error, setError] = useState(null as Error | null);
    const [retry, setRetry] = useState(0);
    useEffect(() => {
        getMediaFile(props.drive, props.recordId, props.mediaRecord.fileName).then(url => {
            setMediaUrl(url);
            setError(null);
        }).catch((e) => setError(e));
    }, [props.drive, props.recordId, props.mediaRecord, retry]);
    if (error) {
        return error instanceof ResourceNotFoundError ? <NotFoundSmall/> :
            <ErrorSmall error={error} retryClickHandler={() => setRetry(retry + 1)}/>
    }
    const markers = new Array<MeasurementMarker>();
    props.measurement.forEach(m => {
        if (m.location) {
            markers.push({
                text: `${formatUciProScaleValue(m.scale, m.avg)} ${formatUciProScaleName(t, m.scale)}`,
                location: m.location
            } as MeasurementMarker);
        }
    });
    return (
        <div className="d-flex flex-column flex-grow-1">
            {!mediaUrl ? <LoaderSmall/> :
                <MeasurementsWithPhotoPreview key={`ph-${retry}`} url={mediaUrl} markers={markers}
                                              retryClickListener={() => setRetry(retry + 1)}
                                              imageLoadListener={props.imageLoadListener}/>}
        </div>
    );
}
