import {SpreadSheetMeasurementData} from "../../models/SpreadSheetMeasurementData";
import "./MeasurementSpreadSheet.css"

interface Props {
    spreadsheet: SpreadSheetMeasurementData;
    values: number[];
    formatter: (v: number) => string;
}

export function MeasurementSpreadSheet(props: Props) {
    const rows = [];
    for (let i = 0; i < props.spreadsheet.height; i++) {
        const cells = []
        for (let j = 0; j < props.spreadsheet.width; j++) {
            const valueIdx = props.spreadsheet.cells.find(c => c.x === j && c.y === i)?.idx;
            cells.push(<td key={`spreadsheet-cell-${i}-${j}`}>
                <div className="d-flex align-items-center justify-content-around">
                    {valueIdx !== undefined ? props.formatter(props.values[valueIdx]) : ""}
                </div>
            </td>)
        }
        rows.push(<tr key={`spreadsheet-row-${i}`}>
            {cells}
        </tr>)
    }
    return (
        <div className="d-flex align-items-center justify-content-around measurement-details-container spreadsheet">
            <table>
                <tbody>
                {rows}
                </tbody>
            </table>
        </div>
    )
}
