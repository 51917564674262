import {Carousel, Modal} from "react-bootstrap";
import {useState} from "react";
import {Loader} from "../Loader/Loader";
import {LineChartData, MeasurementsLineChart} from "./MeasurementsLineChart";
import './MeasurementDetails.css';
import {getMeasurementError} from "../../models/UciProScale";
import {formatUciProScaleName, formatUciProScaleValue} from "../../helpers/FormatHelper";
import {MeasurementsTable} from "./MeasurementsTable";
import {useTranslation} from "react-i18next";
import {UciProMeasurement} from "../../models/UciProMeasurement";
import {MeasurementSpreadSheet} from "./MeasurementSpreadSheet";

interface Props {
    measurement: UciProMeasurement | null;
    closeHandler: () => void;
    modalContainer: HTMLDivElement | undefined;
}

export function UciProMeasurementDetails(props: Props) {
    const {t} = useTranslation();
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex: number) => {
        setIndex(selectedIndex);
    };
    const getLinesChartData = () => {
        const data = new Array<LineChartData>();
        let measurement = props.measurement;
        if (measurement) {
            for (let i = 0; i < measurement.count; i++) {
                data.push({
                    x: i + 1,
                    y: measurement.measurements[i].value.value ?? 0
                });
            }
        }
        return data;
    };
    const getSpreadSheetData = () => {
        const data = new Array<number>();
        let measurement = props.measurement;
        if (measurement) {
            for (let i = 0; i < measurement.count; i++) {
                data.push(measurement.measurements[i].value.value ?? 0);
            }
        }
        return data;
    };
    const getTableData = () => {
        const data = new Array<number>();
        let measurement = props.measurement;
        if (measurement) {
            for (let i = 0; i < measurement.count; i++) {
                data.push(measurement.measurements[i].value.value ?? 0);
            }
        }
        return data;
    };
    return (
        <Modal show={props.measurement !== null} size="lg" centered onHide={props.closeHandler}
               container={props.modalContainer}>
            <Modal.Header closeButton>{t('measurements')}</Modal.Header>
            <Modal.Body>
                {props.measurement ?
                    <Carousel activeIndex={index} onSelect={handleSelect} controls interval={null}>
                        <Carousel.Item>
                            <MeasurementsLineChart classNames="measurement-details-container" data={getLinesChartData()}
                                                   measurementError={getMeasurementError(props.measurement.scale)}
                                                   name={formatUciProScaleName(t, props.measurement.scale)}
                                                   valueFormatter={v => `${formatUciProScaleValue(props.measurement?.scale ?? "", v)} ${formatUciProScaleName(t, props.measurement?.scale ?? "")}`}/>
                        </Carousel.Item>
                        <Carousel.Item>
                            <MeasurementsTable data={getTableData()}
                                               formatter={v => formatUciProScaleValue(props.measurement?.scale ?? "", v)}/>
                        </Carousel.Item>
                        {props.measurement.spreadSheet &&
                            <Carousel.Item>
                                <MeasurementSpreadSheet spreadsheet={props.measurement.spreadSheet}
                                                        values={getSpreadSheetData()}
                                                        formatter={v => `${formatUciProScaleValue(props.measurement?.scale ?? "", v)}`}/>
                            </Carousel.Item>
                        }
                    </Carousel> : <Loader/>
                }
            </Modal.Body>
        </Modal>
    );
}
