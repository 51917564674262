import {DPM, LEEB, MF1M, TP1M, TUD2, TUD3, UCI, UCI_PRO, UT1M, UT1M_CT, UT1M_IP} from "../models/DeviceType";
import * as TudProbe from "../models/TudProbe";
import * as TudScale from "../models/TudScale";
import * as LeebScale from "../models/LeebScale";
import * as UciScale from "../models/UciScale";
import * as UciProScale from "../models/UciProScale";
import * as TpProbe from "../models/TpProbe";
import * as TpScale from "../models/TpScale";
import * as UtScale from "../models/UtScale";
import * as MfScale from "../models/MfScale";
import i18next, {TFunction} from "i18next";
import numeral from 'numeral';

export function formatDateTime(locale: string, dateTime : Date){
    return `${dateTime.toLocaleDateString(locale)} ${dateTime.toLocaleTimeString(locale, {timeStyle: "short"})}`;
}


export function formatNumber(n: number, optionalFractionDigits? : number, mandatoryFractionDigits? : number, minLength? : number) : string{
    const v = numeral(n);
    let format = "";
    if (minLength){
        for (let i = 0; i < minLength; i++) {
            format = "0" + format;
        }
    } else {
        format += "0"
    }
    if ((mandatoryFractionDigits && mandatoryFractionDigits > 0) || (optionalFractionDigits && optionalFractionDigits > 0)){
        format += ".";
    }
    if (mandatoryFractionDigits){
        for (let i = 0; i < mandatoryFractionDigits; i++) {
            format += "0";
        }
    }
    if (optionalFractionDigits){
        const n = optionalFractionDigits - (mandatoryFractionDigits ?? 0);
        if (n > 0) {
            format += "[";
            for (let i = 0; i < n; i++) {
                format += "0";
            }
            format += "]";
        }
    }
    return v.format(format);
}

export function formatDeviceName(t: TFunction<"translation">, deviceType: string){
    switch (deviceType) {
        case TUD2:
            return t("tud2_display_name");
        case TUD3:
            return t("tud3_display_name");
        case LEEB:
            return t("leeb_display_name");
        case UCI:
            return t("uci_display_name");
        case UCI_PRO:
            return t("uci_pro_display_name");
        case TP1M:
            return t("tp2020_display_name");
        case UT1M:
            return t("ut1m_display_name");
        case UT1M_IP:
            return t("ut1m_ip_display_name");
        case UT1M_CT:
            return t("ut1m_ct_display_name");
        case MF1M:
            return t("mf1m_display_name");
        case DPM:
            return t("dpm_display_name");
        default:
            return deviceType;
    }
}

export function formatTudProbeName(t: TFunction<"translation">, probe: number){
    switch (probe){
        case TudProbe.NO_PROBE_VALUE:
            return t("not_connected");
        case TudProbe.UCI_VALUE:
        case TudProbe.UCI_NEW_VALUE:
            return t("ultrasonic_probe");
        case TudProbe.REBOUND_VALUE:
        case TudProbe.REBOUND_OLD_VALUE:
            return t("dynamic_probe");
        default:
            return t("unknown");
    }
}

export function formatTudScaleName(t: TFunction<"translation">, tudScale: string){
    switch (tudScale){
        case TudScale.HRC:
            return t("hrc");
        case TudScale.HB:
            return t("hb");
        case TudScale.HV:
            return t("hv");
        case TudScale.HRB:
            return t("hrb");
        case TudScale.HS:
            return t("hs");
        case TudScale.USER1:
            return t("user_1");
        case TudScale.USER2:
            return t("user_2");
        case TudScale.USER3:
            return t("user_3");
        case TudScale.MPA:
            return t("mpa");
        case TudScale.HL:
            return t("hl");
        default:
            return "";
    }
}

export function formatLeebScaleName(t: TFunction<"translation">, leebScale: string){
    switch (leebScale){
        case LeebScale.HRC:
            return t("hrc");
        case LeebScale.HB:
            return t("hb");
        case LeebScale.HV:
            return t("hv");
        case LeebScale.HRB:
            return t("hrb");
        case LeebScale.HRA:
            return t("hra");
        case LeebScale.HSD:
            return t("hsd");
        case LeebScale.USER1:
            return t("user_1");
        case LeebScale.USER2:
            return t("user_2");
        case LeebScale.MPA:
            return t("mpa");
        case LeebScale.HL:
            return t("hl");
        default:
            return "";
    }
}

export function formatUciScaleName(t: TFunction<"translation">, uciScale: string, customUciScaleName? : string){
    if (customUciScaleName && customUciScaleName !== ''){
        return customUciScaleName;
    }
    switch (uciScale){
        case UciScale.HRC:
            return t("hrc");
        case UciScale.HB:
            return t("hb");
        case UciScale.HV:
            return t("hv");
        case UciScale.HRB:
            return t("hrb");
        case UciScale.HS:
            return t("hs");
        case UciScale.USER1:
            return t("user_1");
        case UciScale.USER2:
            return t("user_2");
        case UciScale.USER3:
            return t("user_3");
        case UciScale.MPA_T:
            return t("mpa_t");
        case UciScale.MPA_Y:
            return t("mpa_y");
        default:
            return "";
    }
}

export function formatUciProScaleName(t: TFunction<"translation">, uciProScale: string) {
    switch (uciProScale) {
        case UciProScale.HRC:
            return t("hrc");
        case UciProScale.HB:
            return t("hb");
        case UciProScale.HV:
            return t("hv");
        case UciProScale.HRB:
            return t("hrb");
        case UciProScale.HS:
            return t("hs");
        case UciProScale.HK:
            return t("hk");
        default:
            return "";
    }
}

export function formatEnTudScaleName(tudScale: string){
    const t = i18next.getFixedT("en");
    return formatTudScaleName(t, tudScale);
}

export function formatEnLeebScaleName(leebScale: string){
    const t = i18next.getFixedT("en");
    return formatLeebScaleName(t, leebScale);
}

export function formatEnUciScaleName(uciScale: string, customUciScaleName?: string){
    const t = i18next.getFixedT("en");
    return formatUciScaleName(t, uciScale, customUciScaleName);
}

export function formatEnUciProScaleName(uciProScale: string) {
    const t = i18next.getFixedT("en");
    return formatUciProScaleName(t, uciProScale);
}

export function formatTudScaleValue(tudScale: string, value: number){
    switch (tudScale){
        case TudScale.HRC:
            return value.toFixed(1);
        case TudScale.HB:
            return value.toFixed(0);
        case TudScale.HV:
            return value.toFixed(0);
        case TudScale.HRB:
            return value.toFixed(1);
        case TudScale.HS:
            return value.toFixed(0);
        case TudScale.USER1:
            return value.toFixed(1);
        case TudScale.USER2:
            return value.toFixed(1);
        case TudScale.USER3:
            return value.toFixed(1);
        case TudScale.MPA:
            return value.toFixed(0);
        case TudScale.HL:
            return value.toFixed(0);
        default:
            return value.toFixed(1);
    }
}

export function formatLeebScaleValue(leebScale: string, value: number){
    switch (leebScale){
        case LeebScale.HRC:
            return value.toFixed(1);
        case LeebScale.HB:
            return value.toFixed(0);
        case LeebScale.HV:
            return value.toFixed(0);
        case LeebScale.HRB:
            return value.toFixed(1);
        case LeebScale.HRA:
            return value.toFixed(1);
        case LeebScale.HSD:
            return value.toFixed(0);
        case LeebScale.USER1:
            return value.toFixed(1);
        case LeebScale.USER2:
            return value.toFixed(1);
        case LeebScale.MPA:
            return value.toFixed(0);
        case LeebScale.HL:
            return value.toFixed(0);
        default:
            return value.toFixed(1);
    }
}

export function formatUciScaleValue(uciScale: string, value: number){
    switch (uciScale){
        case UciScale.HRC:
            return value.toFixed(1);
        case UciScale.HB:
            return value.toFixed(0);
        case UciScale.HV:
            return value.toFixed(0);
        case UciScale.HRB:
            return value.toFixed(1);
        case UciScale.HS:
            return value.toFixed(0);
        case UciScale.USER1:
            return value.toFixed(1);
        case UciScale.USER2:
            return value.toFixed(1);
        case UciScale.USER3:
            return value.toFixed(1);
        case UciScale.MPA_T:
            return value.toFixed(0);
        case UciScale.MPA_Y:
            return value.toFixed(0);
        default:
            return value.toFixed(1);
    }
}

export function formatUciProScaleValue(scale: string, value: number) {
    switch (scale) {
        case UciProScale.HRC:
            return value.toFixed(1);
        case UciProScale.HB:
            return value.toFixed(0);
        case UciProScale.HV:
            return value.toFixed(0);
        case UciProScale.HRB:
            return value.toFixed(1);
        case UciProScale.HS:
            return value.toFixed(0);
        case UciProScale.HK:
            return value.toFixed(0);
        default:
            return value.toFixed(1);
    }
}

export function formatTpScaleName(t: TFunction<"translation">, tpScale: string){
    switch (tpScale){
        case TpScale.MM:
            return t("mm");
        case TpScale.MKM:
            return t("um");
        case TpScale.INCH:
            return t("in");
        case TpScale.MILS:
            return t("mil");
        default:
            return "";
    }
}

export function formatEnTpScaleName(tpScale: string){
    const t = i18next.getFixedT("en");
    return formatTpScaleName(t, tpScale);
}

export function formatTpScaleValue(tpScale: string, value: number, notConvert? : boolean){
    const convertValue = notConvert ? value : TpScale.convertValue(tpScale, value);
    switch (tpScale){
        case TpScale.MM:
            return convertValue.toFixed(3);
        case TpScale.MKM:
            return convertValue.toFixed(0);
        case TpScale.INCH:
            return convertValue.toFixed(4);
        case TpScale.MILS:
            return convertValue.toFixed(1);
        default:
            return value.toFixed(0);
    }
}

export function formatTpProbeName(t: TFunction<"translation">, probe?: number){
    switch (probe){
        case TpProbe.NO_PROBE_VALUE:
            return t("not_connected");
        case TpProbe.F0_3:
            return t("probe_f_0_3");
        case TpProbe.F0_5:
            return t("probe_f_0_5");
        case TpProbe.F2:
            return t("probe_f_2");
        case TpProbe.F5:
            return t("probe_f_5");
        case TpProbe.NF0_5:
            return t("probe_nf_0_5");
        case TpProbe.NF2:
            return t("probe_nf_2");
        case TpProbe.M12:
            return t("probe_m_12");
        case TpProbe.M30:
            return t("probe_m_30");
        case TpProbe.M60:
            return t("probe_m_60");
        case TpProbe.DSH1:
            return t("probe_dsh1");
        case TpProbe.DTVR:
            return t("probe_dtvr");
        case TpProbe.DT:
            return t("probe_dt");
        default:
            return t("unknown");
    }
}

export function formatUtScaleName(t: TFunction<"translation">, utScale: string){
    switch (utScale){
        case UtScale.MM:
            return t("mm");
        case UtScale.INCH:
            return t("in");
        default:
            return "";
    }
}

export function formatEnUtScaleName(utScale: string){
    const t = i18next.getFixedT("en");
    return formatUtScaleName(t, utScale);
}

export function formatUtScaleValue(value : number, discreteness : number){
    return value.toFixed(discreteness);
}

export function formatMfScaleName(t: TFunction<"translation">, mfScale: string){
    switch (mfScale){
        case MfScale.GAUSS:
            return t("scale_g");
        case MfScale.ASM:
            return t("scale_a_cm");
        case MfScale.MTS:
            return t("scale_mt");
        case MfScale.CODE:
            return t("scale_cod");
        default:
            return "";
    }
}

export function formatEnMfScaleName(mfScale: string){
    const t = i18next.getFixedT("en");
    return formatMfScaleName(t, mfScale);
}

export function formatMfScaleValue(mfScale: string, value : number){
    switch (mfScale){
        case MfScale.GAUSS:
        case MfScale.ASM:
            return value.toFixed(1);
        case MfScale.MTS:
            return value.toFixed(2);
        case MfScale.CODE:
            return value.toFixed(0);
        default:
            return "";
    }
}
