export const HRC = "HRC";
export const HB = "HB";
export const HV = "HV";
export const HRB = "HRB";
export const HS = "HS";
export const HK = "HK";

export function getMeasurementError(scale: string) {
    switch (scale) {
        case HRC:
        case HRB:
            return 4;
        case HB:
        case HV:
            return 20;
        case HS:
        case HK:
            return 10;
        default:
            return 10;
    }
}
