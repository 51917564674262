import {NovotestLabRecord} from "../../models/NovotestLabRecord";
import {DPM, LEEB, MF1M, TP1M, TUD2, TUD3, UCI, UCI_PRO, UT1M, UT1M_CT, UT1M_IP} from "../../models/DeviceType";
import {TudMeasurement} from "../../models/TudMeasurement";
import {UciMeasurement} from "../../models/UciMeasurement";
import {UtMeasurement} from "../../models/UtMeasurement";
import {TudPhotoPreview} from "./TudPhotoPreview";
import {UciPhotoPreview} from "./UciPhotoPreview";
import {TpPhotoPreview} from "./TpPhotoPreview";
import {UtPhotoPreview} from "./UtPhotoPreview";
import {MfMeasurement} from "../../models/MfMeasurement";
import {MfPhotoPreview} from "./MfPhotoPreview";
import {UtRecord} from "../../models/UtRecord";
import {
    COATING_THICKNESS_MEASUREMENT_TYPE,
    DEW_POINT_MEASUREMENT_TYPE,
    SURFACE_TEMPERATURE_MEASUREMENT_TYPE,
    TpRecord
} from "../../models/TpRecord";
import {TpSurfaceTemperatureMeasurementPhotoPreview} from "./TpSurfaceTemperatureMeasurementPhotoPreview";
import {TpDewPointMeasurementPhotoPreview} from "./TpDewPointMeasurementPhotoPreview";
import {LeebMeasurement} from "../../models/LeebMeasurement";
import {LeebPhotoPreview} from "./LeebPhotoPreview";
import {DpmPhotoPreview} from "./DpmPhotoPreview";
import {DpmMeasurement} from "../../models/DpmMeasurement";
import {UciProMeasurement} from "../../models/UciProMeasurement";
import {UciProPhotoPreview} from "./UciProPhotoPreview";

interface Props {
    drive: any;
    recordId: string;
    record: NovotestLabRecord;
}

export function NovotestLabPhotoPreview(props: Props) {
    if (props.record.photo) {
        switch (props.record.deviceType) {
            case TUD2:
            case TUD3:
                return <TudPhotoPreview drive={props.drive} recordId={props.recordId}
                                        mediaRecord={props.record.photo}
                                        measurement={props.record.measurements as TudMeasurement[]}/>
            case LEEB:
                return <LeebPhotoPreview drive={props.drive} recordId={props.recordId}
                                        mediaRecord={props.record.photo}
                                        measurement={props.record.measurements as LeebMeasurement[]}/>
            case UCI:
                return <UciPhotoPreview drive={props.drive} recordId={props.recordId}
                                        mediaRecord={props.record.photo!}
                                        measurement={props.record.measurements as UciMeasurement[]}/>
            case UCI_PRO:
                return <UciProPhotoPreview drive={props.drive} recordId={props.recordId}
                                           mediaRecord={props.record.photo!}
                                           measurement={props.record.measurements as UciProMeasurement[]}/>
            case TP1M:
                const tpRecord = props.record as TpRecord;
                const measurementType = tpRecord.measurementType ?? COATING_THICKNESS_MEASUREMENT_TYPE;
                if (measurementType === COATING_THICKNESS_MEASUREMENT_TYPE && tpRecord.measurements) {
                    return <TpPhotoPreview drive={props.drive} recordId={props.recordId}
                                           mediaRecord={props.record.photo!}
                                           measurement={tpRecord.measurements}/>
                }
                if (measurementType === SURFACE_TEMPERATURE_MEASUREMENT_TYPE && tpRecord.surfaceTemperatureMeasurements) {
                    return <TpSurfaceTemperatureMeasurementPhotoPreview drive={props.drive} recordId={props.recordId}
                                                                        mediaRecord={props.record.photo!}
                                                                        measurement={tpRecord.surfaceTemperatureMeasurements}/>
                }
                if (measurementType === DEW_POINT_MEASUREMENT_TYPE && tpRecord.dewPointMeasurements) {
                    return <TpDewPointMeasurementPhotoPreview drive={props.drive} recordId={props.recordId}
                                                              mediaRecord={props.record.photo!}
                                                              measurement={tpRecord.dewPointMeasurements}/>
                }
                break;
            case UT1M:
            case UT1M_IP:
            case UT1M_CT:
                return <UtPhotoPreview drive={props.drive} recordId={props.recordId}
                                       mediaRecord={props.record.photo!}
                                       measurement={props.record.measurements as UtMeasurement[]}
                                       showAverage={(props.record as UtRecord).showAverage ?? false}/>
            case MF1M:
                return <MfPhotoPreview drive={props.drive} recordId={props.recordId}
                                       mediaRecord={props.record.photo!}
                                       measurement={props.record.measurements as MfMeasurement[]}/>
            case DPM:
                return <DpmPhotoPreview drive={props.drive} recordId={props.recordId}
                                       mediaRecord={props.record.photo!}
                                       measurement={props.record.measurements as DpmMeasurement[]}/>
        }
    }
    return <div/>;
}
