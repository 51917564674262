import {MeasurementInfo} from "./MeasurementInfo";
import {formatUciProScaleName, formatUciProScaleValue} from "../../helpers/FormatHelper";
import {useTranslation} from "react-i18next";
import {UciProMeasurement} from "../../models/UciProMeasurement";

interface Props {
    measurement: UciProMeasurement;
    detailsClickListener: (measurement: UciProMeasurement) => void;
}

export function UciProMeasurementWithoutPhoto(props: Props) {
    const {t} = useTranslation();
    const avg = `${formatUciProScaleValue(props.measurement.scale, props.measurement.avg)} ${formatUciProScaleName(t, props.measurement.scale)}`;
    const min = formatUciProScaleValue(props.measurement.scale, props.measurement.min);
    const max = formatUciProScaleValue(props.measurement.scale, props.measurement.max);
    const count = props.measurement.count.toFixed(0);
    const detailsClickHandler = () => {
        props.detailsClickListener(props.measurement);
    };
    return (
        <MeasurementInfo avg={avg} min={min} max={max} count={count} detailsClickListener={detailsClickHandler}/>
    );
}
