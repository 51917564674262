import {Button, Modal, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Loader} from "../Loader/Loader";
import React, {Fragment, useEffect, useState} from "react";
import {getAwpFwDeviceCloudType} from "../../helpers/AwpFwFormatHelper";
import {collection, getDocsFromServer, orderBy, query, where} from "firebase/firestore";
import {firebaseStorage, firestore} from "../../index";
import {AwpFwInfo} from "../../models/AwpFwInfo";
import {AwpDeviceInfo} from "../../models/AwpDeviceInfo";
import {formatNumber} from "../../helpers/FormatHelper";
import {getDownloadURL, ref} from "firebase/storage";
import {GSP, GSPS, NVP, NVT, ZIP} from "../../models/AwpFwFileType";
import {logError} from "../../helpers/LogHelper";
import {SERVICE_MODE_DISABLED, SERVICE_MODE_PRODUCTION, SERVICE_MODE_QC} from "../../helpers/ServiceModeHelper";


interface Message {
    text: string;
    isLoader: boolean;
}

interface Props {
    show: number;
    closeHandler: () => void;
    deviceInfo: AwpDeviceInfo;
    fwSelectionHandler: (fwInfo: AwpFwInfo, fileName: string, fileType: number, content: ArrayBuffer) => void;
    serviceMode: string;
}

function searchFw(serviceMode: string, deviceTypeId: number | undefined, hwVersion: number, swVersion: number, bootloader?: string): Promise<AwpFwInfo[]> {
    const fwCollectionRef = collection(firestore, "firmwares");
    const fwQuery = query(fwCollectionRef,
        where("deviceType", "==", getAwpFwDeviceCloudType(deviceTypeId)),
        orderBy("SW", "desc"));
    return getDocsFromServer(fwQuery).then(snapshot => {
        const fwData = new Array<AwpFwInfo>();
        snapshot.forEach(doc => {
            const docData = doc.data();
            if ((serviceMode === SERVICE_MODE_QC) || (serviceMode === SERVICE_MODE_PRODUCTION && docData.status !== "fail") || (serviceMode === SERVICE_MODE_DISABLED && docData.status === "release")) {
                fwData.push({
                    deviceType: docData.deviceType,
                    sw: docData.SW,
                    swVersion: docData.SWVersion,
                    hwMin: docData.HWmin,
                    hwMax: docData.HWmax,
                    bootloader: docData.bootloader,
                    type: docData.type,
                    changeLog: docData.changeLog,
                    status: docData.status,
                    fileId: doc.id
                } as AwpFwInfo);
            }
        })
        let fwList = new Array<AwpFwInfo>();
        let latestSw = undefined;
        let latestHw = undefined;
        for (const item of fwData) {
            if (!latestSw && !latestHw && hwVersion < item.hwMin) {
                latestSw = item.sw;
                latestHw = item.hwMin;
            }
            if (item.sw >= swVersion && hwVersion >= item.hwMin && hwVersion <= item.hwMax) {
                if (fwList.length > 0) {
                    if (item.sw !== fwList[0].sw) {
                        break;
                    }
                }
                item.latestHw = latestHw;
                item.latestSw = latestSw;
                if (bootloader === item.bootloader || !item.bootloader) {
                    fwList.push(item);
                }
            }
        }
        return fwList;
    });
}

export function FwSearchDialog(props: Props) {
    const {t} = useTranslation();
    const [error, setError] = useState(undefined as string | undefined)
    const [fwInfo, setFwInfo] = useState(undefined as AwpFwInfo[] | undefined | null);
    useEffect(() => {
        if (props.show % 2 === 1) {
            setError(undefined);
            setFwInfo(undefined);
            searchFw(props.serviceMode, props.deviceInfo.deviceTypeId, props.deviceInfo.hwVersion, props.deviceInfo.swVersion, props.deviceInfo.bootloader)
                .then(fwInfo => {
                    setFwInfo(fwInfo);
                }).catch(e => {
                logError("Fw query error", e);
                setError(t("awp_fw_download_failed"));
            });
        }
    }, [t, props.show, props.deviceInfo]);
    const selectFw = (fwInfo: AwpFwInfo | null | undefined) => {
        if (fwInfo) {
            setFwInfo(null);
            const fileRef = ref(firebaseStorage, `Firmwares/${fwInfo.fileId}`);
            getDownloadURL(fileRef)
                .then(url => fetch(url))
                .then(async response => {
                    if (response.ok) {
                        try {
                            const fwData = await response.arrayBuffer();
                            let fwType;
                            switch (fwInfo.type) {
                                case "gsp":
                                    fwType = GSP;
                                    break;
                                case "gsps":
                                    fwType = GSPS;
                                    break;
                                case "nvt":
                                    fwType = NVT;
                                    break;
                                case "nvp":
                                    fwType = NVP;
                                    break;
                                case "zip":
                                    fwType = ZIP;
                                    break;
                                default:
                                    throw new Error("Unsupported file");
                            }
                            let device = fwInfo.deviceType.toUpperCase();
                            if (device === "LEEB_PLAIN") {
                                device = "LEEB_LITE";
                            }
                            const version = fwInfo.swVersion ?? fwInfo.sw.toString().replaceAll(".", "_");
                            const type = fwInfo.type;
                            const name = `${device}_SW_${version}.${type}`;
                            props.fwSelectionHandler(fwInfo, name, fwType, fwData);
                            close();
                        } catch (e) {
                            logError("Response process error", e);
                            setError(t("awp_fw_download_failed"));
                        }
                    }
                }).catch(() => setError(t("awp_fw_download_failed")));
        }
    }
    const close = () => {
        props.closeHandler();
    }
    const formatSwVersion = (info: AwpFwInfo): string => {
        return info.swVersion ?? formatNumber(info.sw, 3, 1);
    }
    return (
        <Modal show={props.show % 2 === 1} onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>{t("loader_fw_search")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <div>{error}</div>}
                {error === undefined &&
                    <Fragment>
                        {fwInfo === null &&
                            <Loader className={"mt-4"} message={t("awp_fw_downloading")}/>
                        }
                        {fwInfo === undefined &&
                            <Loader className={"mt-4"} message={t("awp_fw_device_version_awaiting_data")}/>
                        }
                        {fwInfo !== undefined && fwInfo !== null && fwInfo.length === 0 &&
                            <div>{t("awp_fw_not_found")}</div>
                        }
                        {fwInfo !== undefined && fwInfo !== null && fwInfo.length === 1 &&
                            <Fragment>
                                <div>{t("awp_fw_found", {version: formatSwVersion(fwInfo[0])})}</div>
                                {props.deviceInfo.swVersion > 0 && props.deviceInfo.swVersion === fwInfo[0].sw &&
                                    <Fragment>
                                        <br/>
                                        <br/>
                                        <div>{t("awp_fw_already_latest")}</div>
                                    </Fragment>
                                }
                            </Fragment>
                        }
                        {fwInfo !== undefined && fwInfo !== null && fwInfo.length > 1 &&
                            <Fragment>
                                <div>{t('awp_fw_found_few')}</div>
                                <div className="my-2">{t('awp_fw_select')}</div>
                                <Table striped bordered className="fw-table mb-0">
                                    <tbody>
                                    {fwInfo.map((info, i) =>
                                        <tr key={`fw-opt-${i}`}>
                                            <td className="text-start">{t("awp_fw_version_format", {version: formatSwVersion(info)})}</td>
                                            <td className="text-end" style={{width: "120px"}}><Button variant="primary"
                                                                                                      onClick={() => selectFw(info)}>{t("select")}</Button>
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </Table>
                                {props.deviceInfo.swVersion > 0 && props.deviceInfo.swVersion === fwInfo[0].sw &&
                                    <Fragment>
                                        <br/>
                                        <br/>
                                        <div>{t("awp_fw_already_latest")}</div>
                                    </Fragment>
                                }
                            </Fragment>
                        }
                    </Fragment>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={close}>{t("cancel")}</Button>
                <Button variant="primary" onClick={() => selectFw(fwInfo![0])}
                        disabled={error !== undefined || fwInfo === undefined || fwInfo === null || fwInfo.length !== 1}>{t("ok")}</Button>
            </Modal.Footer>
        </Modal>
    )
        ;
}
