import {TpMeasurement} from "../models/TpMeasurement";
import {TudMeasurement} from "../models/TudMeasurement";
import {getAllMeasurements, UtMeasurement} from "../models/UtMeasurement";
import {
    formatEnLeebScaleName,
    formatEnMfScaleName,
    formatEnTpScaleName,
    formatEnTudScaleName,
    formatEnUciProScaleName,
    formatEnUciScaleName,
    formatEnUtScaleName,
    formatLeebScaleValue,
    formatMfScaleValue,
    formatNumber,
    formatTpScaleValue,
    formatTudScaleValue,
    formatUciProScaleValue,
    formatUciScaleValue,
    formatUtScaleValue
} from "../helpers/FormatHelper";
import {UciMeasurement} from "../models/UciMeasurement";
import {AwpTudSeries} from "../models/AwpTudSeries";
import {
    formatAwpTudKrcValue,
    formatEnAwpTudKrcScaleDescription,
    formatEnAwpTudKrcScaleName
} from "../helpers/AwpTudKrcFormatHelper";
import {AwpTpSeries} from "../models/AwpTpSeries";
import {formatAwpTpValue, formatEnAwpTpScaleName} from "../helpers/AwpTpFormatHelper";
import {AwpUtSeries} from "../models/AwpUtSeries";
import {formatAwpUtValue, formatEnAwpUtScaleName} from "../helpers/AwpUtFormatHelper";
import {AwpMfSeries} from "../models/AwpMfSeries";
import {formatAwpMfValue, formatEnAwpMfScaleName} from "../helpers/AwpMfFormatHelper";
import {AwpUd2301Series} from "../models/AwpUd2301Series";
import {DATA_PLACEHOLDER} from "../AppSettings";
import {AwpUd2303Series} from "../models/AwpUd2303Series";
import {AwpUd3701Series} from "../models/AwpUd3701Series";
import {AwpUt3EmaSeries} from "../models/AwpUt3EmaSeries";
import {AwpUt2aSeries} from "../models/AwpUt2aSeries";
import {MfMeasurement} from "../models/MfMeasurement";
import {AwpIpsmSeries} from "../models/AwpIpsmSeries";
import {formatAwpIpsmValue, formatEnAwpIpsmScaleName} from "../helpers/AwpIpsmFormatHelper";
import {TpSurfaceTemperatureMeasurement} from "../models/TpSurfaceTemperatureMeasurement";
import {TpDewPointMeasurement} from "../models/TpDewPointMeasurement";
import {getUt3EmaDeviceInfo, getUt3EmaResult, getUt3EmaTransducerInfo} from "../helpers/AwpUt3EmaFormatHelper";
import i18next from "i18next";
import {getUt2ADeviceInfo, getUt2AResult, getUt2ATransducerInfo} from "../helpers/AwpUt2AFormatHelper";
import {
    getUd2301DeviceInfo,
    getUd2301Result,
    getUd2301StrobeInfo,
    getUd2301TransducerInfo
} from "../helpers/AwpUd2301FormatHelper";
import {
    getUd3701DeviceInfo,
    getUd3701Result,
    getUd3701StrobeInfo,
    getUd3701TransducerInfo
} from "../helpers/AwpUd3701FormatHelper";
import {
    getUd2303DeviceInfo,
    getUd2303DResult,
    getUd2303StrobeInfo,
    getUd2303TransducerInfo
} from "../helpers/AwpUd2303FormatHelper";
import {LeebMeasurement} from "../models/LeebMeasurement";
import {DpmMeasurement} from "../models/DpmMeasurement";
import {UciProMeasurement} from "../models/UciProMeasurement";

export interface CsvSeries {
    title: string;
    columnTitles: Array<string>;
    columnData: Array<Array<string>>
}

export function makeTpCsvSeries(title: string, measurement: TpMeasurement) {
    const tpScale = measurement.tpScale;
    const columnTitles = ["Measuring", `Thickness [${formatEnTpScaleName(tpScale)}]`];
    const columnData = new Array<Array<string>>();
    for (let i = 0; i < measurement.n; i++) {
        columnData.push([`${i + 1}`, formatTpScaleValue(tpScale, measurement.real[i])]);
    }
    return {
        title: title,
        columnTitles: columnTitles,
        columnData: columnData
    };
}

export function makeTpSurfaceTemperatureCsvSeries(title: string, measurements: TpSurfaceTemperatureMeasurement[]) {
    const columnTitles = ["Measuring", "Temperature [°C]"];
    const columnData = new Array<Array<string>>();
    for (let i = 0; i < measurements.length; i++) {
        columnData.push([`${i + 1}`, formatNumber(measurements[i].temperature, 1, 1)]);
    }
    return {
        title: title,
        columnTitles: columnTitles,
        columnData: columnData
    };
}

export function makeTpDewPointCsvSeries(title: string, measurements: TpDewPointMeasurement[]) {
    const columnTitles = ["Measuring", "Air temperature [°C]", "Air humidity [%]", "Dew point [°C]"];
    const columnData = new Array<Array<string>>();
    for (let i = 0; i < measurements.length; i++) {
        columnData.push([`${i + 1}`, formatNumber(measurements[i].airTemperature, 1, 1), formatNumber(measurements[i].airHumidity, 1, 1), formatNumber(measurements[i].dewPoint, 1, 1)]);
    }
    return {
        title: title,
        columnTitles: columnTitles,
        columnData: columnData
    };
}

export function makeTudCsvSeries(title: string, measurement: TudMeasurement) {
    const tudScale = measurement.tudScale;
    const columnTitles = ["Measuring", `Hardness [${formatEnTudScaleName(tudScale)}]`];
    const columnData = new Array<Array<string>>();
    for (let i = 0; i < measurement.n; i++) {
        columnData.push([`${i + 1}`, formatTudScaleValue(tudScale, measurement.real[i])]);
    }
    return {
        title: title,
        columnTitles: columnTitles,
        columnData: columnData
    };
}

export function makeLeebCsvSeries(title: string, measurement: LeebMeasurement) {
    const leebScale = measurement.leebScale;
    const columnTitles = ["Measuring", `Hardness [${formatEnLeebScaleName(leebScale)}]`];
    const columnData = new Array<Array<string>>();
    for (let i = 0; i < measurement.n; i++) {
        columnData.push([`${i + 1}`, formatLeebScaleValue(leebScale, measurement.real[i])]);
    }
    return {
        title: title,
        columnTitles: columnTitles,
        columnData: columnData
    };
}

export function makeUciCsvSeries(title: string, measurement: UciMeasurement) {
    const uciScale = measurement.uciScale;
    const uciScaleCustomName = measurement.uciScaleCustomName;
    const columnTitles = ["Measuring", `Hardness [${formatEnUciScaleName(uciScale, uciScaleCustomName)}]`];
    const columnData = new Array<Array<string>>();
    for (let i = 0; i < measurement.count; i++) {
        columnData.push([`${i + 1}`, formatUciScaleValue(uciScale, measurement.data[i].value ?? 0)]);
    }
    return {
        title: title,
        columnTitles: columnTitles,
        columnData: columnData
    };
}

export function makeUciProCsvSeries(title: string, measurement: UciProMeasurement) {
    const uciProScale = measurement.scale;
    const columnTitles = ["Measuring", `Hardness [${formatEnUciProScaleName(uciProScale)}]`];
    const columnData = new Array<Array<string>>();
    for (let i = 0; i < measurement.count; i++) {
        columnData.push([`${i + 1}`, formatUciProScaleValue(uciProScale, measurement.measurements[i].value.value ?? 0)]);
    }
    return {
        title: title,
        columnTitles: columnTitles,
        columnData: columnData
    };
}

export function makeUtCsvSeries(title: string, measurement: UtMeasurement) {
    const utScale = measurement.utScale;
    const columnTitles = ["Measuring", `Thickness [${formatEnUtScaleName(utScale)}]`];
    const columnData = new Array<Array<string>>();
    const rawMeasurements = getAllMeasurements(measurement);
    for (let i = 0; i < rawMeasurements.length; i++) {
        columnData.push([`${i + 1}`, formatUtScaleValue(rawMeasurements[i].thickness, measurement.discreteness)]);
    }
    return {
        title: title,
        columnTitles: columnTitles,
        columnData: columnData
    };
}

export function makeMfCsvSeries(title: string, measurement: MfMeasurement) {
    const mfScale = measurement.mfScale;
    const columnTitles = ["Measuring", `Magnetic field [${formatEnMfScaleName(mfScale)}]`];
    const columnData = new Array<Array<string>>();
    const rawMeasurements = measurement.measurements;
    for (let i = 0; i < rawMeasurements.length; i++) {
        columnData.push([`${i + 1}`, formatMfScaleValue(mfScale, rawMeasurements[i])]);
    }
    return {
        title: title,
        columnTitles: columnTitles,
        columnData: columnData
    };
}

export function makeDpmCsvSeries(title: string, measurements: DpmMeasurement[]) {
    const columnTitles = ["Measuring", "Air humidity [%]", "Surface temperature [°C]", "Air temperature [°C]", "Dew point [°C]", "Difference [°C]"];
    const columnData = new Array<Array<string>>();
    for (let i = 0; i < measurements.length; i++) {
        const dpmMeasurement = measurements[i];
        if (dpmMeasurement.measurements.length > 0) {
            const rawMeasurement = dpmMeasurement.measurements[dpmMeasurement.measurements.length - 1];
            columnData.push([`${i + 1}`, formatNumber(rawMeasurement.humidity, 1, 1), formatNumber(rawMeasurement.tempSurface, 1, 1), formatNumber(rawMeasurement.tempAir, 1, 1), formatNumber(rawMeasurement.devPoint, 1, 1), formatNumber(rawMeasurement.devSurface, 1, 1)]);
        }
    }
    return {
        title: title,
        columnTitles: columnTitles,
        columnData: columnData
    };
}

export function makeAwpTudKrcCsvSeries(title: string, measurement: AwpTudSeries) {
    const scale = measurement.scale;
    const probeType = measurement.probeParam.typeProbe;
    const columnTitles = ["Measuring", `${formatEnAwpTudKrcScaleDescription(probeType)} [${formatEnAwpTudKrcScaleName(scale, probeType)}]`];
    const columnData = new Array<Array<string>>();
    for (let i = 0; i < measurement.measurement.n; i++) {
        columnData.push([`${i + 1}`, formatAwpTudKrcValue(measurement.measurement.real[i])]);
    }
    return {
        title: title,
        columnTitles: columnTitles,
        columnData: columnData
    };
}

export function makeAwpTpSeries(title: string, measurement: AwpTpSeries) {
    const scale = measurement.scale;
    const columnTitles = ["Measuring", `Thickness [${formatEnAwpTpScaleName(scale)}]`];
    const columnData = new Array<Array<string>>();
    for (let i = 0; i < measurement.n; i++) {
        columnData.push([`${i + 1}`, formatAwpTpValue(measurement.real[i], measurement.scale)]);
    }
    return {
        title: title,
        columnTitles: columnTitles,
        columnData: columnData
    };
}

export function makeAwpUtSeries(title: string, measurement: AwpUtSeries) {
    const scale = measurement.measure.scale;
    const columnTitles = ["Measuring", `Thickness [${formatEnAwpUtScaleName(scale)}]`];
    const columnData = new Array<Array<string>>();
    let thinc = measurement.measure.saveThinc;
    if (measurement.measure.scale === 1){
        thinc /= 25.4;
    }
    columnData.push([`1`, formatAwpUtValue(thinc)]);
    return {
        title: title,
        columnTitles: columnTitles,
        columnData: columnData
    };
}

export function makeAwpMfSeries(title: string, measurement: AwpMfSeries) {
    const scale = measurement.scale;
    const columnTitles = ["Measuring", `Induction [${formatEnAwpMfScaleName(scale)}]`];
    const columnData = new Array<Array<string>>();
    columnData.push([`1`, formatAwpMfValue(measurement.strength[scale], scale)]);
    return {
        title: title,
        columnTitles: columnTitles,
        columnData: columnData
    };
}

export function makeAwpIpsmSeries(title: string, measurement: AwpIpsmSeries) {
    const columnTitles = ["Measuring", "Value"];
    const columnData = new Array<Array<string>>();
    for (let i = 0; i < measurement.values.length; i++) {
        columnData.push([`${i + 1}`, `${formatAwpIpsmValue(measurement.values[i].value, measurement.values[i].scale)} ${formatEnAwpIpsmScaleName(measurement.values[i].scale)}`]);
    }
    return {
        title: title,
        columnTitles: columnTitles,
        columnData: columnData
    };
}

export function makeAwpUd2301Series(data: AwpUd2301Series) {
    const t = i18next.getFixedT("en");
    const csv = new Array<CsvSeries>();
    const deviceInfo = getUd2301DeviceInfo(t, data);
    const transducerInfo = getUd2301TransducerInfo(t, data);
    const strobeInfo = getUd2301StrobeInfo(t, data);
    const result = getUd2301Result(t, data);
    csv.push({
        title: "UD2301 parameters",
        columnTitles: ["Parameter", "Value"],
        columnData: [
            ["Gain [dB]", deviceInfo.gain],
            ["Delay [mm]", deviceInfo.delayMm],
            ["Delay [us]", deviceInfo.delayUs],
            ["Scanning [mm]", deviceInfo.scanningMm],
            ["Scanning [us]", deviceInfo.scanningUs],
            ["Detector", deviceInfo.detector],
            ["Point averaging", deviceInfo.middle],
            ["Filter [MHz]", deviceInfo.filter],
            ["TVG", deviceInfo.tvg]
        ]
    });
    csv.push({
        title: "Transducer",
        columnTitles: ["Parameter", "Value"],
        columnData: [
            ["Frequency [MHz]", transducerInfo.freq],
            ["Input angle", transducerInfo.angle],
            ["Delay in the prism [us]", transducerInfo.delay],
            ["Arrow [mm]", transducerInfo.vector]
        ]
    });
    csv.push({
        title: "Gates and measurement results",
        columnTitles: ["Parameter", "Gate A", "Gate B"],
        columnData: [
            ["Start [mm]", strobeInfo.startMm1, strobeInfo.startMm2],
            ["Start [us]", strobeInfo.startUs1, strobeInfo.startUs2],
            ["Width [mm]", strobeInfo.widthMm1, strobeInfo.widthMm2],
            ["Width [us]", strobeInfo.widthUs1, strobeInfo.widthUs2],
            ["Level [%]", strobeInfo.level1, strobeInfo.level2],
            ["Control [dB]", strobeInfo.control1, strobeInfo.control2],
            ["Search [dB]", strobeInfo.search1, strobeInfo.search2],
            ["Mode", strobeInfo.mode1, strobeInfo.mode2]
        ]
    });
    csv.push({
        title: "",
        columnTitles: ["Parameter", "Gate A", "Gate B", "A - B"],
        columnData: [
            ["T [us]", result.tA, result.tB, result.tAB],
            ["A [dB]", result.aA, result.aB, result.aAB],
            ["Xb [mm]", result.xbA, result.xbB, ""],
            ["Xip [mm]", result.xipA, result.xipB, ""],
            ["Y [mm]", result.yA, result.yB, result.yAB],
            ["S [mm²]", result.sA, result.sB, ""],
            ["L [mm]", result.lA, result.lB, result.lAB],
            ["dA [dB]", result.daA, result.daB, result.daAB]
        ]
    });
    return csv;
}

export function makeAwpUd2303Series(data: AwpUd2303Series) {
    const t = i18next.getFixedT("en");
    const csv = new Array<CsvSeries>();
    const deviceInfo = getUd2303DeviceInfo(t, data);
    const result = getUd2303DResult(t, data);
    const strobeInfo = getUd2303StrobeInfo(t, data);
    const transducerInfo = getUd2303TransducerInfo(t, data);
    csv.push({
        title: "UD2303 parameters",
        columnTitles: ["Parameter", "Value"],
        columnData: [
            ["Gain [dB]", deviceInfo.gain],
            ["Delay [mm]", deviceInfo.delayMm],
            ["Delay [in]", deviceInfo.delayInch],
            ["Scanning [mm]", deviceInfo.scanMm],
            ["Scanning [in]", deviceInfo.scanInch],
            ["Detector", deviceInfo.detector],
            ["Point averaging", deviceInfo.middle],
            ["Filter [MHz]", deviceInfo.filter],
            ["TVG", deviceInfo.tvg]
        ]
    });
    csv.push({
        title: "Transducer",
        columnTitles: ["Parameter", "Value"],
        columnData: [
            ["Frequency [MHz]", transducerInfo.freq],
            ["Input angle", transducerInfo.angle],
            ["Delay in the prism [us]", transducerInfo.delayUs],
            ["Arrow [mm]", transducerInfo.vector]
        ]
    });
    csv.push({
        title: "Gates and measurement results",
        columnTitles: ["Parameter", "Gate A", "Gate B"],
        columnData: [
            ["Start [mm]", strobeInfo.startMm1, strobeInfo.startMm2],
            ["Start [in]", strobeInfo.startInch1, strobeInfo.startInch2],
            ["Width [mm]", strobeInfo.widthMm1, strobeInfo.widthMm2],
            ["Width [in]", strobeInfo.widthInch1, strobeInfo.widthInch2],
            ["Level [%]", strobeInfo.level1, strobeInfo.level2],
            ["Control [dB]", strobeInfo.control1, strobeInfo.control2],
            ["Search [dB]", strobeInfo.search1, strobeInfo.search2],
            ["Mode", strobeInfo.mode1, strobeInfo.mode2]
        ]
    });
    csv.push({
        title: "",
        columnTitles: ["Parameter", "Gate A", "Gate B", "A - B"],
        columnData: [
            ["T [us]", result.tA, result.tB, result.tAB],
            ["A [dB]", result.aA, result.aB, result.aAB],
            ["Xb [mm]", result.xbA, result.xbB, ""],
            ["Xip [mm]", result.xipA, result.xipB, ""],
            ["Y [mm]", result.yA, result.yB, result.yAB],
            ["S [mm²]", result.sA, result.sB, ""],
            ["L [mm]", result.lA, result.lB, result.lAB],
            ["dA [dB]", result.daA, result.daB, result.daAB]
        ]
    });
    return csv;
}

export function makeAwpUd3701Series(data: AwpUd3701Series) {
    const t = i18next.getFixedT("en");
    const csv = new Array<CsvSeries>();
    const deviceInfo = getUd3701DeviceInfo(t, data);
    const result = getUd3701Result(t, data);
    const strobeInfo = getUd3701StrobeInfo(t, data);
    const transducerInfo = getUd3701TransducerInfo(t, data);
    csv.push({
        title: "UD3701 parameters",
        columnTitles: ["Parameter", "Value"],
        columnData: [
            ["Gain [dB]", deviceInfo.gain],
            ["Delay [mm]", deviceInfo.delayMm],
            ["Delay [us]", deviceInfo.delayUs],
            ["Scanning [mm]", deviceInfo.scanMm],
            ["Scanning [us]", deviceInfo.scanUs],
            ["Detector", deviceInfo.detector],
            ["Point averaging", deviceInfo.middle],
            ["Filter [MHz]", deviceInfo.filter],
            ["TVG", deviceInfo.tvg]
        ]
    });
    csv.push({
        title: "Transducer",
        columnTitles: ["Parameter", "Value"],
        columnData: [
            ["Frequency [MHz]", transducerInfo.freq],
            ["Input angle", transducerInfo.angle],
            ["Delay in the prism [us]", transducerInfo.delay],
            ["Arrow [mm]", transducerInfo.vector],
            ["Diameter [mm]", transducerInfo.diamSyzeA]
        ]
    });
    csv.push({
        title: "Gates and measurement results",
        columnTitles: ["Parameter", "Gate A", "Gate B"],
        columnData: [
            ["Start [mm]", strobeInfo.startMm1, strobeInfo.startMm2],
            ["Start [us]", strobeInfo.startUs1, strobeInfo.startUs2],
            ["Width [mm]", strobeInfo.widthMm1, strobeInfo.widthMm2],
            ["Width [us]", strobeInfo.widthUs1, strobeInfo.widthUs2],
            ["Level [%]", strobeInfo.level1, strobeInfo.level2],
            ["Control [dB]", strobeInfo.control1, strobeInfo.control2],
            ["Search [dB]", strobeInfo.search1, strobeInfo.search2],
            ["Mode", strobeInfo.mode1, strobeInfo.mode2]
        ]
    });
    csv.push({
        title: "",
        columnTitles: ["Parameter", "Gate A", "Gate B", "A - B"],
        columnData: [
            ["T [us]", result.tA, result.tB, result.tAB],
            ["A [dB]", result.aA, result.aB, result.aAB],
            ["Xb [mm]", result.xbA, result.xbB, ""],
            ["Xip [mm]", result.xipA, result.xipB, ""],
            ["Y [mm]", result.yA, result.yB, result.yAB],
            ["S [mm²]", result.sA, result.sB, ""],
            ["L [mm]", result.lA, result.lB, result.lAB],
            ["dA [dB]", result.daA, result.daB, result.daAB]
        ]
    });
    return csv;
}


export function makeAwpUt3EmaSeries(data: AwpUt3EmaSeries) {
    const csv = new Array<CsvSeries>();
    const t = i18next.getFixedT("en");
    const deviceInfo = getUt3EmaDeviceInfo(t, data);
    const transducerInfo = getUt3EmaTransducerInfo(t, data);
    const result = getUt3EmaResult(t, data);
    csv.push({
        title: "UT-3M-EMA parameters",
        columnTitles: ["Parameter", "Value"],
        columnData: [
            ["Working mode", deviceInfo.workMode],
            ["Measurement mode", deviceInfo.measMode],
            ["Gain [dB]", deviceInfo.gain],
            ["Point averaging", deviceInfo.middle],
        ]
    });
    csv.push({
        title: "Transducer",
        columnTitles: ["Parameter", "Value"],
        columnData: [
            ["Sensor name", transducerInfo.name],
            ["Sensor type", transducerInfo.type],
            ["Frequency [MHz]", transducerInfo.freq],
            ["Amplitude", transducerInfo.amplV],
            ["Pulses", transducerInfo.nPulses],
        ]
    });

    if (result.value) {
        csv.push({
            title: "Measurement",
            columnTitles: [result.value, result.units],
            columnData: []
        });
    }
    return csv;
}

export function makeAwpUt2aSeries(data: AwpUt2aSeries) {
    const csv = new Array<CsvSeries>();
    const t = i18next.getFixedT("en");
    const deviceInfo = getUt2ADeviceInfo(t, data);
    const transducerInfo = getUt2ATransducerInfo(t, data);
    const result = getUt2AResult(t, data);
    csv.push({
        title: "UT-2A parameters",
        columnTitles: ["Parameter", "Value"],
        columnData: [
            ["Working mode", deviceInfo.workMode !== "" ? deviceInfo.workMode : DATA_PLACEHOLDER],
            ["Measurement mode", deviceInfo.measMode !== "" ? deviceInfo.measMode : DATA_PLACEHOLDER],
            ["Gain [dB]", deviceInfo.gain],
            ["Point averaging", deviceInfo.middle],
            [`Range [${deviceInfo.scanningAndDelayUnits}]`, deviceInfo.scanning],
            [`Delay [${deviceInfo.scanningAndDelayUnits}]`, deviceInfo.delay],
            [`Velocity [${deviceInfo.velocityUnits}]`, deviceInfo.velocity],
        ]
    });
    csv.push({
        title: "Transducer",
        columnTitles: ["Parameter", "Value"],
        columnData: [
            ["Sensor name", transducerInfo.name],
            ["Sensor type", transducerInfo.type],
            ["Frequency [MHz]", transducerInfo.freq],
            ["Delay [us]", transducerInfo.delay],
            ["Amplitude", transducerInfo.amplV],
            ["Pulses", transducerInfo.nPulses],
        ]
    });
    if (result.value) {
        csv.push({
            title: "Measurement",
            columnTitles: [result.value, result.units],
            columnData: []
        });
    }
    return csv;
}
