import {ArchiveItem} from "../models/ArchiveItem";
import {BaseRecord} from "../models/BaseRecord";
import {TpRecord} from "../models/TpRecord";
import {TudRecord} from "../models/TudRecord";
import {UtRecord} from "../models/UtRecord";
import * as DeviceType from "../models/DeviceType";
import {API_KEY} from "../AppSettings";
import {RecordWrapper} from "../models/RecordWrapper";
import {
    ArchiveFolderCreationError,
    GoogleDriveError,
    GoogleDriveRootFolderNotFoundError,
    ResourceNotFoundError
} from "../errors/GoogleDriveErrors";
import {isValidUciRecord, UciRecord} from "../models/UciRecord";
import {UciRecordValidationError} from "../errors/RecordValidationError";
import {MfRecord} from "../models/MfRecord";
import {LeebRecord} from "../models/LeebRecord";
import {DpmRecord} from "../models/DpmRecord";
import {UciProRecord} from "../models/UciProRecord";

const FOLDER_MIME = "application/vnd.google-apps.folder";
const GOOGLE_DRIVE_ROOT_FOLDER_ID = "root";
const ROOT_FOLDER_NAME = "NOVOTEST Archive";
const RECORD_FOLDER_NAME_SUFFIX = ".record";
const RECORD_DATA_FILE_NAME = "data.json";
const MAX_QUERY_LENGTH = 3500;
const MAX_USAGE_LIMIT_RETRY = 4;
const MAX_USAGE_RETRY_DELAY_BASE_MS = 1000;
const MAX_CONCURRENT_REQUESTS = 50;
const CONCURRENT_REQUESTS_DELAY_MS = 250;

export class GoogleDriveApiHelper {

    private static files(drive: any): gapi.client.drive.FilesResource {
        return drive.files as gapi.client.drive.FilesResource;
    }

    static shareRecord(drive: any, recordId: string, attempt?: number): Promise<boolean> {
        const permissions = drive.permissions as gapi.client.drive.PermissionsResource;
        return permissions.create({fileId: recordId, key: API_KEY}, {role: "reader", type: "anyone"})
            .then(response => {
                return response.result.id;
            }).catch(e => {
                return this.handleUsageLimitsError(e, (attempt => this.shareRecord(drive, recordId, attempt)), attempt);
            });
    }

    private static getUserArchiveRootFolder(drive: any) {
        return this.files(drive).list({
            q: `mimeType = '${FOLDER_MIME}' and '${GOOGLE_DRIVE_ROOT_FOLDER_ID}' in parents and name = '${ROOT_FOLDER_NAME}' and trashed = false`,
            spaces: "drive",
            key: API_KEY
        }).then(response => response.result.files);
    }

    static getArchiveRootFolder(drive: any, attempt?: number): Promise<ArchiveItem> {
        return this.getUserArchiveRootFolder(drive).then(driveFiles => {
                if (driveFiles) {
                    if (driveFiles.length > 0) {
                        const file = driveFiles[0];
                        const archiveItem = this.makeArchiveItemWithoutIndexing(file);
                        if (archiveItem) {
                            return archiveItem;
                        }
                    } else {
                        return this.files(drive).create({}, {
                            mimeType: FOLDER_MIME,
                            name: ROOT_FOLDER_NAME,
                            parents: [GOOGLE_DRIVE_ROOT_FOLDER_ID]
                        }).then(response => {
                            const file = response.result;
                            const archiveItem = this.makeArchiveItemWithoutIndexing(file);
                            if (archiveItem) {
                                return archiveItem;
                            }
                            throw new ArchiveFolderCreationError();
                        })
                    }
                }
                throw new GoogleDriveError();
            }
        ).catch(e => {
            return this.handleUsageLimitsError(e, (attempt => this.getArchiveRootFolder(drive, attempt)), attempt);
        });
    }

    static getArchiveItems(drive: any, where: string | string[], shouldIndexRecords: boolean): Promise<Array<ArchiveItem>> {
        return this.findItems(drive, where).then(files => {
            if (files) {
                return this.makeArchiveItems(drive, files, shouldIndexRecords);
            }
            throw new GoogleDriveError();
        });
    }

    private static buildAuthorizedItemSearchQuery(parents: string[]) {
        let queries = new Array<string>();
        const baseQuery = `mimeType = '${FOLDER_MIME}' and trashed = false and (`;
        let queryString = baseQuery;
        let isFirst = true;
        for (const p of parents) {
            if (isFirst) {
                isFirst = false;
            } else {
                queryString += ' or'
            }
            queryString += `'${p}' in parents`;
            if (queryString.length > MAX_QUERY_LENGTH) {
                queryString += ')';
                queries.push(queryString);
                queryString = baseQuery;
                isFirst = true;
            }
        }
        if (!isFirst) {
            queryString += ')';
            queries.push(queryString);
        }
        return queries;
    }

    private static findItems(drive: any, where: string | string[]): Promise<Array<gapi.client.drive.File> | undefined> {
        if (where instanceof Array && where.length === 0) {
            return Promise.resolve([]);
        }
        let parents;
        if (where instanceof Array) {
            parents = where;
        } else {
            parents = [where as string];
        }
        const queries = this.buildAuthorizedItemSearchQuery(parents);
        return Promise.all(queries.map(q => this.findItemsWithQuery(drive, q))).then(r => {
            const files = new Array<gapi.client.drive.File>();
            r.forEach(f => files.push(...f));
            return files;
        }).catch(() => {
            return undefined;
        });
    }

    private static findItemsWithQuery(drive: any, query: string, result?: Array<gapi.client.drive.File>, nextPageToken?: string, attempt?: number): Promise<Array<gapi.client.drive.File>> {
        return this.files(drive).list({
            q: query,
            fields: "nextPageToken, files(id, name, parents, mimeType, modifiedTime, appProperties)",
            spaces: "drive",
            pageSize: 1000,
            pageToken: nextPageToken,
            key: API_KEY
        }).then(response => {
            const files = result ? result : new Array<gapi.client.drive.File>();
            if (response.result.files) {
                files.push(...response.result.files);
            } else {
                throw new GoogleDriveError();
            }
            if (response.result.nextPageToken) {
                return this.findItemsWithQuery(drive, query, files, response.result.nextPageToken, attempt);
            } else {
                return files;
            }
        }).catch(e => {
            return this.handleUsageLimitsError(e, (attempt => this.findItemsWithQuery(drive, query, undefined, undefined, attempt)), attempt);
        });
    }

    private static makeArchiveItems(drive: any, files: gapi.client.drive.File[], shouldIndexRecords: boolean): Promise<Array<ArchiveItem>> {
        const archiveItems = files.map(f => this.makeArchiveItemWithoutIndexing(f)).filter(i => i != null).map(i => i as ArchiveItem);
        if (shouldIndexRecords) {
            const recordFolderIds = archiveItems.filter(i => !i.isFolder).map(i => i.id);
            return this.indexRecordsByRecordIds(drive, recordFolderIds).then(recordWrappers => {
                const result = new Array<ArchiveItem>();
                result.push(...archiveItems.filter(i => i.isFolder));
                const records = archiveItems.filter(i => !i.isFolder);
                records.forEach(r => {
                    const rw = recordWrappers.find(rw => rw.id === r.id);
                    if (rw) {
                        r.lastChanged = new Date(rw.record.dateTime);
                    }
                })
                result.push(...records);
                return result;
            })
        } else {
            return Promise.resolve(archiveItems);
        }
    }

    private static makeArchiveItemWithoutIndexing(file: gapi.client.drive.File): ArchiveItem | null {
        if (file.id && file.name) {
            const timestamp = Number(file.appProperties?.timestamp);
            let isFolder = !file.name.endsWith(RECORD_FOLDER_NAME_SUFFIX);
            const name = isFolder ? file.name : file.name.substr(0, file.name.length - RECORD_FOLDER_NAME_SUFFIX.length);
            const lastChanged = isNaN(timestamp) ? new Date(file.modifiedTime ?? "") : new Date(timestamp);
            return {
                id: file.id,
                name: name,
                isFolder: isFolder,
                lastChanged: lastChanged
            } as ArchiveItem;
        }
        return null;
    }

    static getPath(drive: any, id: string): Promise<ArchiveItem[]> {
        return this.getAuthorizedPath(drive, id);
    }

    private static getAuthorizedPath(drive: any, id: string, attempt?: number): Promise<ArchiveItem[]> {
        return this.getRootFolderId(drive)
            .then(rootId => this.getAutorizedPathSegment(drive, rootId, id, ...[]))
            .catch(e => {
                return this.handleUsageLimitsError(e, (attempt => this.getAuthorizedPath(drive, id, attempt)), attempt);
            });
    }

    private static getRootFolderId(drive: any, attempt?: number): Promise<string> {
        return this.files(drive).get({
            fileId: GOOGLE_DRIVE_ROOT_FOLDER_ID,
            key: API_KEY
        }).then(response => {
            const file = response.result;
            if (file && file.id) {
                return file.id;
            }
            throw new GoogleDriveRootFolderNotFoundError();
        }).catch(e => {
            return this.handleUsageLimitsError(e, (attempt => this.getRootFolderId(drive, attempt)), attempt);
        });
    }

    private static getAutorizedPathSegment(drive: any, rootId: string, id: string, ...path: ArchiveItem[]): Promise<ArchiveItem[]> {
        return this.files(drive).get({
            fileId: id,
            fields: "id, name, parents, mimeType",
            key: API_KEY
        }).then(response => {
            const file = response.result;
            if (file) {
                if (file.id && file.name && file.parents) {
                    const archiveItem = {
                        id: file.id,
                        name: file.name,
                        isFolder: true,
                        lastChanged: new Date()
                    } as ArchiveItem;
                    const resultPath = new Array<ArchiveItem>();
                    resultPath.push(archiveItem);
                    resultPath.push(...path);
                    if (file.name === ROOT_FOLDER_NAME && file.parents.includes(rootId)) {
                        return resultPath;
                    }
                    const parents = file.parents.filter(parentId => parentId !== rootId);
                    if (parents.length > 0) {
                        return Promise.allSettled(parents.map(parentId => this.getAutorizedPathSegment(drive, rootId, parentId, ...resultPath)))
                            .then(results => {
                                const fulfilledResults = results.filter(r => r.status === "fulfilled").map(r => r as PromiseFulfilledResult<ArchiveItem[]>);
                                if (fulfilledResults.length > 0) {
                                    return fulfilledResults[0].value;
                                }
                                if (results.filter(r => r.status === "rejected").map(r => r as PromiseRejectedResult).every(r => r.reason instanceof ResourceNotFoundError)) {
                                    throw new ResourceNotFoundError();
                                }
                                throw new GoogleDriveError();
                            });
                    }
                }
            }
            throw new ResourceNotFoundError();
        }).catch(e => {
            const status = e.status;
            if (status === 404) {
                throw new ResourceNotFoundError();
            }
            throw e;
        });
    }

    static indexRecords(drive: any): Promise<Array<RecordWrapper>> {
        return this.getArchiveRootFolder(drive)
            .then(item => this.findRecordsInFolder(drive, item.id))
            .then(ids => this.indexRecordsByRecordIds(drive, ids));
    }

    private static findRecordsInFolder(drive: any, id: string | string[]): Promise<Array<string>> {
        return this.getArchiveItems(drive, id, false).then(items => {
            const folders = items.filter(i => i.isFolder).map(i => i.id);
            if (folders.length > 0) {
                return this.findRecordsInFolder(drive, folders).then(results => {
                    const result = new Array<string>();
                    result.push(...items.filter(i => !i.isFolder).map(i => i.id));
                    result.push(...results);
                    return result;
                });
            } else {
                return Promise.resolve(items.filter(i => !i.isFolder).map(i => i.id));
            }
        });
    }

    private static buildAuthorizedRecordSearchQueries(recordIds: Array<string>): Array<string> {
        let queries = new Array<string>();
        const baseQuery = `name='${RECORD_DATA_FILE_NAME}' and trashed = false and (`;
        let queryString = baseQuery;
        let isFirst = true;
        for (const p of recordIds) {
            if (isFirst) {
                isFirst = false;
            } else {
                queryString += ' or'
            }
            queryString += `'${p}' in parents`;
            if (queryString.length > MAX_QUERY_LENGTH) {
                queryString += ')';
                queries.push(queryString);
                queryString = baseQuery;
                isFirst = true;
            }
        }
        if (!isFirst) {
            queryString += ')';
            queries.push(queryString);
        }
        return queries;
    }

    private static findRecords(drive: any, recordIds: Array<string>): Promise<Array<gapi.client.drive.File>> {
        let queries = this.buildAuthorizedRecordSearchQueries(recordIds);
        return Promise.all(queries.map(q => this.findItemsWithQuery(drive, q))).then(r => {
            const files = new Array<gapi.client.drive.File>();
            r.forEach(f => files.push(...f));
            return files;
        })
    }

    private static indexRecordsByRecordIds(drive: any, recordIds: Array<string>): Promise<Array<RecordWrapper>> {
        return this.findRecords(drive, recordIds).then(async responseFiles => {
            const token = gapi?.client?.getToken()?.access_token;
            const recordsData: Array<[string, string]> = responseFiles.map(f => [f.parents?.find(p => recordIds.includes(p)) ?? "", f.id ?? ""]);
            return this.indexRecordsByRecordFileIds(drive, recordsData, token);
        });
    }

    private static indexRecordsByRecordFileIds(drive: any, recordsData: Array<[recordId: string, fileId: string]>, token: string, results?: Array<RecordWrapper>): Promise<Array<RecordWrapper>> {
        const result = results ?? new Array<RecordWrapper>();
        if (recordsData.length === 0) {
            return Promise.resolve(result);
        } else {
            const portion = recordsData.splice(0, MAX_CONCURRENT_REQUESTS);
            const promises = portion.map(([recordId, fileId]) => this.indexRecordByRecordFileId(drive, recordId, fileId, token));
            return (results ? this.sleep(CONCURRENT_REQUESTS_DELAY_MS) : Promise.resolve()).then(() => Promise.all(promises).then(results => {
                const localResult = new Array<RecordWrapper>();
                for (const record of results) {
                    if (record) {
                        localResult.push(record);
                    }
                }
                return result.concat(localResult);
            })).then(results => this.indexRecordsByRecordFileIds(drive, recordsData, token, results));
        }
    }

    private static indexRecordByRecordFileId(drive: any, recordId: string, recordFileId: string, token: string | null, attempt?: number): Promise<RecordWrapper | null> {
        const url = `https://www.googleapis.com/drive/v3/files/${recordFileId}?alt=media&key=${API_KEY}`;
        let promise;
        if (token) {
            promise = fetch(url, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
        } else {
            promise = fetch(url);
        }
        return promise.then(response => {
            if (response.ok) {
                return response.text().then(json => {
                    const baseRecord = JSON.parse(json.replaceAll("NaN", "0")) as BaseRecord;
                    return {
                        id: recordId,
                        record: baseRecord
                    } as RecordWrapper;
                }).catch(() => null);
            }
            throw new ResourceNotFoundError();
        }).catch(e => {
            return this.handleUsageLimitsError(e, (attempt => this.indexRecordByRecordFileId(drive, recordId, recordFileId, token, attempt)), attempt);
        });
    }

    static getRecord(drive: any, id: string, onlyMy?: boolean, attempt?: number): Promise<TpRecord | TudRecord | UciRecord | UciProRecord | UtRecord | DpmRecord> {
        return this.files(drive).list({
            q: `'${id}' in parents and name='${RECORD_DATA_FILE_NAME}' and trashed = false ${onlyMy ? "and 'me' in owners" : ""}`,
            spaces: "drive",
            key: API_KEY
        }).then(response => {
            const responseFiles = response.result.files;
            if (responseFiles) {
                if (responseFiles.length > 0) {
                    const responseFile = responseFiles[0];
                    const responseFileId = responseFile.id;
                    if (responseFileId) {
                        const url = `https://www.googleapis.com/drive/v3/files/${responseFileId}?alt=media&key=${API_KEY}`;
                        const token = gapi?.client?.getToken()?.access_token;
                        if (token) {
                            return fetch(url, {
                                headers: {
                                    "Authorization": `Bearer ${token}`
                                }
                            });
                        } else {
                            return fetch(url);
                        }
                    }
                } else {
                    throw new ResourceNotFoundError();
                }
            }
            throw new GoogleDriveError();
        }).then(response => {
            if (response.ok) {
                return response.text();
            }
            throw new ResourceNotFoundError();
        }).then(json => {
            const baseRecord = JSON.parse(json.replaceAll("NaN", "0")) as BaseRecord;
            switch (baseRecord.deviceType) {
                case DeviceType.TUD2:
                case DeviceType.TUD3:
                    return baseRecord as TudRecord;
                case DeviceType.LEEB:
                    return baseRecord as LeebRecord;
                case DeviceType.UCI:
                    const uciRecord = baseRecord as UciRecord;
                    if (isValidUciRecord(uciRecord)) {
                        return uciRecord;
                    } else {
                        throw new UciRecordValidationError();
                    }
                case DeviceType.UCI_PRO:
                    return baseRecord as UciProRecord;
                case DeviceType.TP1M:
                    return baseRecord as TpRecord;
                case DeviceType.UT1M:
                case DeviceType.UT1M_IP:
                case DeviceType.UT1M_CT:
                    return baseRecord as UtRecord;
                case DeviceType.MF1M:
                    return baseRecord as MfRecord;
                case DeviceType.DPM:
                    return baseRecord as DpmRecord;
            }
            throw new ResourceNotFoundError();
        }).catch(e => {
            return this.handleUsageLimitsError(e, (attempt => this.getRecord(drive, id, onlyMy, attempt)), attempt);
        });
    }

    static getMediaRecordUrl(drive: any, recordId: string, name: string, attempt?: number): Promise<string> {
        return this.files(drive).list({
            q: `'${recordId}' in parents and name='${name}' and trashed = false`,
            spaces: "drive",
            fields: "files(id, name, webContentLink)",
            key: API_KEY
        }).then(response => {
            const responseFiles = response.result.files;
            if (responseFiles) {
                if (responseFiles.length > 0) {
                    const link = responseFiles[0].webContentLink;
                    if (link) {
                        return link;
                    }
                } else {
                    throw new ResourceNotFoundError();
                }
            }
            throw new GoogleDriveError();
        }).catch(e => {
            return this.handleUsageLimitsError(e, (attempt => this.getMediaRecordUrl(drive, recordId, name, attempt)), attempt);
        });
    }

    static getMediaFile(drive: any, id: string, name: string, attempt?: number): Promise<string> {
        return this.files(drive).list({
            q: `'${id}' in parents and name='${name}' and trashed = false`,
            spaces: "drive",
            key: API_KEY
        }).then(response => {
            const responseFiles = response.result.files;
            if (responseFiles) {
                if (responseFiles.length > 0) {
                    const responseFile = responseFiles[0];
                    const responseFileId = responseFile.id;
                    if (responseFileId) {
                        const url = `https://www.googleapis.com/drive/v3/files/${responseFileId}?alt=media&key=${API_KEY}`;
                        const token = gapi?.client?.getToken()?.access_token;
                        if (token) {
                            return fetch(url, {
                                headers: {
                                    "Authorization": `Bearer ${token}`
                                }
                            });
                        } else {
                            return fetch(url);
                        }
                    }
                } else {
                    throw new ResourceNotFoundError();
                }
            }
            throw new GoogleDriveError();
        }).then(response => {
            if (response.ok) {
                return response.blob();
            }
            throw new ResourceNotFoundError();
        }).then(blob => {
            return URL.createObjectURL(blob)
        }).catch(e => {
            return this.handleUsageLimitsError(e, (attempt => this.getMediaFile(drive, id, name, attempt)), attempt);
        });
    }

    private static handleUsageLimitsError(e: any, retryFn: (attempt: number) => any, attempt?: number) {
        if (!attempt || attempt <= MAX_USAGE_LIMIT_RETRY) {
            if (e.status && (e.status === 403 || e.status === 429)) {
                if (e.result && e.result.error && e.result.error.errors) {
                    for (const error of e.result.error.errors) {
                        if (error.domain && error.domain === "usageLimits") {
                            const n = attempt ?? 0;
                            return this.sleep(Math.pow(2, n) * MAX_USAGE_RETRY_DELAY_BASE_MS).then(() => retryFn(n + 1));
                        }
                    }
                }
            }
        }
        throw e;
    }

    private static sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    static deleteItems(drive: any, ids: Array<string>): Promise<boolean> {
        return Promise.allSettled(ids.map(id => this.files(drive).delete({fileId: id})))
            .then(results => {
                return results.every(r => r.status === "fulfilled");
            })
    }

}
